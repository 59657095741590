/* 
  This card class is used in two places on the profile page in the skill section
*/
.card {
  font-size: var(--fs-base);
  text-align: center;
  color: var(--txtClr-base);

  background-color: var(--bgClr-white);
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);

  padding: var(--pd-sm) var(--pd-xs);

  width: 95%;
  margin: 0 auto;

  @include mobile_md {
    padding: var(--pd-base) var(--pd-sm);
  }
  @include tablet_md {
    padding: var(--pd-md) var(--pd-md);
  }
  @include pc_lg {
    font-size: var(--fs-xl);
  }
  @include pc_xl {
    font-size: var(--fs-2xl);
  }
  @include pc_2xl {
    font-size: var(--fs-4xl);
  }

  &__title {
    font-size: var(--fs-base-plus);
    margin-bottom: var(--mg-sm);

    @include pc_lg {
      font-size: var(--fs-2xl);
    }
    @include pc_xl {
      font-size: var(--fs-3xl);
    }
    @include pc_2xl {
      font-size: var(--fs-5xl);
    }
  }

  &__sub {
    margin-bottom: var(--mg-sm) !important;
    line-height: 1.5;
  }

  &__image {
    margin-bottom: var(--mg-base);
    border-radius: 5px;
  }

  &__btn {
    width: 15em;
    margin: 0 auto;
    font-size: var(--fs-sm);

    @include pc_lg {
      font-size: var(--fs-base);
    }
    @include pc_xl {
      font-size: var(--fs-md);
    }
    @include pc_2xl {
      font-size: var(--fs-3xl);
    }
  }

  &__list {
    text-align: left;
    padding: 0;

    &__item {
      margin-bottom: var(--mg-base);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
