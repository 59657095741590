.language {
  display: none;

  &--active {
    display: block;
    animation: fade-in 500ms ease;
  }

  &--closing {
    animation: fade-out 500ms ease;
  }

  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  /* 
    The invisible container takes up the entire screen to trigger the closing animation on click outside of the content area. 
  */

  z-index: 3; // see stacking context note __global.scss

  padding: var(--pd-xs);

  &__list {
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(-27%, 27%);

    @include tablet_md {
      transform: translate(-54%, 33%);
    }

    @include pc_lg {
      font-size: var(--fs-xl);
      transform: translate(-33%, 27%);
    }

    @include pc_xl {
      font-size: var(--fs-2xl);
      transform: translate(-45%, 42%);
    }

    @include pc_2xl {
      font-size: var(--fs-4xl);
      transform: translate(-69%, 45%);
    }

    background-color: var(--bgClr-lightgray);

    border-radius: 5px;

    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);

    padding: var(--pd-sm) var(--pd-sm);

    &::before {
      /* 
        This is the mini triangle that pushes just outside the container 
        to create a better visual connection between the options menu and the language menu icon.
        */
      content: "";
      position: absolute;
      top: -0.1875em; // -3px
      top: -0.6em;
      right: 12%;
      width: 0px; // only the border is the visible part of the element
      height: 0px;

      border-style: solid;
      border-width: 0px 0.75em 0.75em 0.75em;
      border-color: transparent transparent var(--bgClr-lightgray) transparent;
    }
  }

  &__option {
    &__btn {
      border: none;
      width: 100%;

      &:hover {
        background-color: #003f8c;
        color: #fff;
      }
    }
  }
}
