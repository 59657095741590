.horizontal-list {
  /* container */
  overflow-x: auto;
  width: auto;

  background-color: var(--bgClr-lightgray);
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35),
    inset -33px 0px 33px -33px rgba(0, 0, 0, 0.35);

  padding: var(--pd-sm);

  @include tablet_sm {
    padding: var(--pd-base);
  }

  @include pc_sm {
    overflow-x: none;
    width: 78ch;
    padding: var(--pd-md);
    margin: 0 auto;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
  }

  &__list {
    /* Grid: horizontal 4 column on mobile; two columns two rows on pc */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    //grid-template-columns: repeat(3, 22.5%) 32.5%;
    grid-template-columns: repeat(4, auto);
    grid-gap: var(--mg-sm);

    @include pc_sm {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &__item {
    position: relative;
    text-align: center;
    color: var(--txtClr-base);

    border-radius: 5px;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);

    padding: var(--pd-sm) var(--pd-xs);

    @include tablet_md {
      padding: var(--pd-base) var(--pd-sm);
    }

    &:last-of-type::after {
      /* create extra white space to visually indicate end of the list */
      content: "";
      position: absolute;
      top: 0;
      right: calc((-1) * var(--mg-base));

      width: var(--mg-base);
      height: 100%;
    }

    &__content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }

    &__title {
      font-size: var(--fs-base);
      margin-bottom: var(--mg-sm);

      @include tablet_sm {
        font-size: var(--fs-base-plus);
      }
      @include pc_lg {
        font-size: var(--fs-md);
      }
      @include pc_xl {
        font-size: var(--fs-xl);
      }
      @include pc_2xl {
        font-size: var(--fs-4xl);
      }
    }

    &__image-container {
      height: 45%;
      margin-bottom: var(--mg-sm);

      @include pc_sm {
        margin-bottom: var(--mg-base);
      }
    }

    &__image {
      flex: 1;
      border-radius: 5px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__link-container {
      display: flex;
      flex-direction: column;

      width: 100%;
    }

    &__tooltip {
      align-self: center;
    }
  }

  // Scrollbar styling firefox
  scrollbar-color: var(--accent-azureblue) var(--bgClr-white);
  scrollbar-width: none;

  @include pc_sm {
    scrollbar-width: auto;
  }

  // Scrollbar styling webkit based browsers
  &::-webkit-scrollbar {
    background-color: var(--bgClr-accent);
    height: 0.66em;
    display: none;
    @include pc_sm {
      display: block;
    }
  }
  &::-webkit-scrollbar-track {
    background-color: var(--bgClr-white);
    border-radius: 0 0 5px 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--accent-azureblue);
    border-radius: 5px;
    border: 2px solid var(--bgClr-white);
  }
}
