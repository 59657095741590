.consent {
  display: none;
  visibility: hidden;

  &--active {
    display: flex;
    visibility: visible;
  }

  &--closing {
    animation: fade-out 500ms forwards;
  }

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 5;

  background-color: var(--bgClr-dark);

  &__content-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: var(--pd-sm);

    margin: 0 auto;

    @include tablet_md {
      flex-direction: row;
      justify-content: center;
    }

    @include pc_lg {
      font-size: var(--fs-md);
    }
    @include pc_2xl {
      font-size: var(--fs-2xl);
    }
  }

  &__message {
    text-align: center;
    color: var(--txtClr-white);
    margin: 0 0 var(--mg-base) !important;

    @include tablet_md {
      text-align: left;
      margin: 0 var(--mg-base) 0 0 !important;
    }
  }

  &__btn {
    width: 18em;
    font-size: var(--fs-sm);
    margin-bottom: var(--mg-sm);

    @include tablet_md {
      margin-bottom: unset;

      &:first-of-type {
        order: 2;
        margin-left: var(--mg-sm);
      }
    }
    @include pc_lg {
      font-size: var(--fs-base);
    }
    @include pc_xl {
      font-size: var(--fs-md);
    }
    @include pc_2xl {
      font-size: var(--fs-xl);
    }
  }
}
