.list-in-text {
  list-style-type: disc;
  overflow: hidden;

  margin: var(--mg-xs) 0;

  li {
    text-overflow: ellipsis;
    overflow-wrap: break-word;

    margin-left: 1em;
    margin-bottom: var(--mg-xs);

    &::marker {
      color: var(--txtClr-lightblue);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
