.lang-menu-toggle {
  position: relative;
  z-index: 3; // see stacking context note __global.scss

  display: flex;

  cursor: pointer;
  opacity: 0.7;

  &__icon {
    width: 1.25em; // 20px
    height: 1.25em; // 20px

    margin-right: var(--mg-xs);
  }

  &__text {
    font-size: var(--fs-sm);
    color: var(--txtClr-lightblue);

    @include pc_lg {
      font-size: var(--fs-base);
    }
    @include pc_xl {
      font-size: var(--fs-md);
    }
    @include pc_2xl {
      font-size: var(--fs-3xl);
    }
  }
}
