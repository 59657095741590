.cta-contact {
  text-align: center;

  font-size: var(--fs-base-plus);

  padding: var(--pd-xl) var(--pd-xs);
  margin: 0 auto;

  @include mobile_md {
    padding: var(--pd-xl) var(--pd-sm);
  }
  @include pc_lg {
    font-size: var(--fs-xl);
  }
  @include pc_xl {
    font-size: var(--fs-2xl);
  }
  @include pc_2xl {
    font-size: var(--fs-4xl);
  }

  &__content-container {
    margin: 0 auto;
    max-width: 45ch;
  }

  &__heading {
    margin-bottom: var(--mg-sm);

    &__title {
      font-size: var(--fs-xl);
      color: var(--txtClr-white);

      @include pc_lg {
        font-size: var(--fs-2xl);
      }
      @include pc_xl {
        font-size: var(--fs-3xl);
      }
      @include pc_2xl {
        font-size: var(--fs-6xl);
      }
    }
  }

  &__text {
    color: var(--txtClr-lightblue);

    margin-bottom: var(--mg-lg);
  }

  &__link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__link {
    width: 18em;

    @include pc_xl {
      font-size: var(--fs-xl);
    }
    @include pc_2xl {
      font-size: var(--fs-3xl);
    }

    &:first-of-type {
      margin-bottom: var(--mg-base);
    }
  }
}
