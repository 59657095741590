.services {
  // receives base styles from __section-base

  &__heading {
    // section title receives base styles from __section-base
    &__image-container {
      margin: 0 auto var(--mg-md);
      width: 9em;
    }
  }

  &__list-container {
    max-width: 55ch;
    text-align: center;

    margin: 0 auto var(--mg-lg);
  }

  &__list {
    &__item {
      margin-bottom: var(--mg-base);

      &__title {
        font-weight: 600;
        line-height: 1.5;
        font-size: var(--fs-md);

        @include pc_lg {
          font-size: var(--fs-lg);
        }
        @include pc_xl {
          font-size: var(--fs-2xl);
        }
        @include pc_2xl {
          font-size: var(--fs-5xl);
        }
      }
    }
  }
  &__addendum {
    max-width: 75ch;
    margin: 0 auto;
  }
}
