.testimonials {
  font-size: var(--fs-base-plus);

  background-color: var(--bgClr-accent);

  padding: var(--pd-xl) var(--pd-xs);

  @include pc_lg {
    font-size: var(--fs-lg);
  }
  @include pc_xl {
    font-size: var(--fs-xl);
  }
  @include pc_2xl {
    font-size: var(--fs-3xl);
  }

  &__title {
    text-align: center;
    font-size: var(--fs-xl);
    max-width: 45ch;

    color: var(--txtClr-white);

    margin: 0 auto var(--mg-base);

    @include pc_lg {
      font-size: var(--fs-2xl);
    }
    @include pc_xl {
      font-size: var(--fs-3xl);
    }
    @include pc_2xl {
      font-size: var(--fs-6xl);
    }
  }
}

.project {
  display: flex;
  flex-direction: column;

  background-color: var(--bgClr-lightgray);
  box-shadow: 5px 5px 25px 0 rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  padding: var(--pd-base) var(--pd-sm);
  margin: 0 auto var(--mg-lg);

  @include tablet_md {
    padding: var(--pd-base) var(--pd-xl);
  }

  @include pc_sm {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-column-gap: var(--mg-lg);
    grid-row-gap: var(--mg-xs);

    padding: var(--pd-md) var(--pd-xl);

    /* 
      Reverse columns & content every other project
    */
    &:nth-of-type(even) {
      grid-template-columns: 60% 40%;
    }
    &:nth-of-type(even) &__desc {
      grid-column: 2;
    }
    &:nth-of-type(even) &__link-container {
      grid-column: 2;
    }
    &:nth-of-type(even) &__demo {
      grid-column: 1;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__demo {
    margin-bottom: var(--mg-base);

    @include pc_sm {
      grid-column: 2;
      align-self: center;
      margin-bottom: 0;
    }

    &__image,
    &__video {
      border-radius: 5px;
      box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.25);
      margin-bottom: var(--mg-xs);
    }

    &__video {
      width: 100%;
    }

    &__caption {
      text-align: center;
      font-size: var(--fs-sm);
    }
  }

  &__desc {
    margin-bottom: var(--mg-base);

    @include pc_sm {
      grid-row: 1;
      align-self: center;
    }

    &__logo {
      text-align: center;
      margin-bottom: var(--mg-sm);

      &__image {
        width: 54%;
        margin: 0 auto;
        border-radius: 5px;

        @include mobile_lg {
          width: 12em;
        }
        @include pc_sm {
          width: 9em;
        }
      }
    }

    &__text {
      text-align: center;
      max-width: 33ch;
      margin: 0 auto;
    }
  }

  &__link-container {
    @include pc_sm {
      grid-row: 2;
    }
  }

  &__link {
    font-size: var(--fs-sm);
    width: 81%;
    margin: 0 auto;

    &:first-of-type {
      margin-bottom: var(--mg-sm);
    }

    @include mobile_md {
      width: 18em;
    }

    @include pc_lg {
      font-size: var(--fs-base);
    }
    @include pc_xl {
      font-size: var(--fs-lg);
    }
    @include pc_2xl {
      font-size: var(--fs-2xl);
    }
  }
}
