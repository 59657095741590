.menu-toggle {
  position: relative;
  z-index: 3; // see stacking context note __global.scss
  width: 2.25em; //40px
  height: 2.5em; //40px

  &:hover {
    cursor: pointer;
  }

  &__toggle {
    /* 
      Toggle is a hamburger with three components: 
      - the bottom border of the element, this is the middle bar
      - ::before, this the lowest bar
      - ::after, this the highest bar
      - To form an X on open, the middle bar is hidden and the others rotated
    */
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1875em; // 3px
    border-bottom: 0.1875em solid var(--bgClr-gold); // 3px
    border-radius: 0.3125em; // 5px

    transition: all 0.3s ease-in;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.1875em; // 3px
      border-radius: 0.3125em; // 5px
      background-color: var(--bgClr-gold);
    }

    &::before {
      top: -0.625em; // -10px
    }
    &::after {
      top: -1.25em; // -20px
    }

    &--active {
      border-bottom: none;
      animation: fade-in 500ms forwards;

      &::before {
        background-color: var(--txtClr-lightgray);
        transform: rotate(-45deg);
      }
      &::after {
        background-color: var(--txtClr-lightgray);
        transform: rotate(45deg);
        top: -0.625em; // -10px
        right: -0.0625em; // -1px
      }
    }

    &--closing {
      animation: fade-out 500ms forwards;
    }
  }
}
