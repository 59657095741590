.modal {
  //full screen modal assumes a parent with position: relative
  display: none;

  &--active {
    display: block;
    animation: fade-in 500ms forwards;
  }

  &--closing {
    animation: fade-out 500ms forwards;
  }

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 6; // see stacking context note __global.scss
  overflow-y: auto;

  background-color: rgba(3, 31, 48, 0.3);

  padding-top: var(--pd-base);

  &__content-container {
    position: relative;

    width: 90%;

    padding: var(--pd-base) var(--pd-sm);
    margin: 0 auto var(--mg-base);

    background-color: var(--bgClr-dark);
    color: var(--txtClr-gold);

    box-shadow: 5px 5px 5px 8em rgb(233, 235, 238, 0.6);
    border-radius: 5px;
  }
}
