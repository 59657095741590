.header {
  position: relative;
  z-index: 3; // see stacking context note __global.scss

  &__content-container {
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin: 0 auto;

    padding: var(--pd-sm);

    @include tablet_md {
      padding: var(--pd-base) var(--pd-lg) var(--pd-sm);
    }
    @include pc_lg {
      font-size: var(--fs-md);
    }
    @include pc_xl {
      font-size: var(--fs-xl);
      padding: var(--pd-lg) var(--pd-lg) var(--pd-sm);
    }
    @include pc_2xl {
      font-size: var(--fs-4xl);
      padding: var(--pd-lg) var(--pd-xl) var(--pd-sm);
    }
  }

  &__logo {
    position: relative;
    z-index: 3;

    width: 5.4em;

    &__link {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &__svg {
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & button:first-child {
      /* aligns the two toggles as desired */
      margin: var(--mg-sm) var(--mg-xs) 0 0;
    }
  }
}

.menu {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2;

  &--active {
    display: block;
    animation: fade-in 500ms forwards;
  }

  &--closing {
    animation: fade-out 500ms forwards;
  }

  &__nav {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;

    background-color: var(--txtClr-accent);
    border-radius: 5px;
    border-bottom: 6px solid var(--accent-azureblue);

    color: var(--txtClr-white);

    padding: var(--pd-2xl) var(--pd-xs) var(--pd-sm);

    @include pc_lg {
      padding: var(--pd-2xl) var(--pd-sm) var(--pd-base);
    }
    @include pc_xl {
      padding: var(--pd-3xl) var(--pd-base) var(--pd-md);
    }
  }

  &__item {
    width: 18em;

    margin-bottom: var(--mg-sm);

    text-align: center;
    text-transform: capitalize;

    /* There are three types of items: link, link--alt and snow-toggle */
    &--alt &__link {
      border: none;
    }

    &__link {
      width: 100%;

      color: var(--txtClr-white);

      border-radius: 5px;
      border: var(--bgClr-white) solid 1px;

      transition: all 0.3s ease-in-out;

      padding: var(--pd-sm) var(--pd-base);

      &:hover {
        background-color: var(--bgClr-white);
        color: var(--txtClr-accent);
      }
    }

    &__snow-animation-switch span {
      color: var(--txtClr-white);
    }

    &__snow-animation-switch input:checked + label {
      background: var(--bgClr);
    }
  }
}
