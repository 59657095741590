/* 
  Stacking context: 
  - header as whole (value: 2): should appear above hero elements that range from -3 to 1
  - inside the header: The brand logo and menu toggles should appear above the opening header menu. The lang-menu appears above the main menu.
    -- menu__nav: 2
    -- menu-toggle: 3 (see _menu-toggle.scss)
    -- logo: 3
    -- lang-menu-toggle: 3 (see _menu-toggle.scss)
    -- lang-menu: 3 (see _lang-menu.scss)
  - scroll-height-indicator has value 5 
  - modals and tooltips and have value 6
  - noscript element has value 7
  - hidden element in footer has value -1
*/

:root {
  /* COLORS */
  --bgClr: rgb(11, 33, 74, 1);
  --bgClr-accent: rgb(0, 63, 140, 1); //#003f8c
  --bgClr-white: rgba(255, 255, 255, 1); //#ffffff
  --bgClr-lightgray: rgba(235, 237, 238, 1); //#ebedee
  --bgClr-lightblue: rgb(196, 240, 255, 1); //#c4f0ff
  --bgClr-gold: rgba(249, 227, 174, 25%); //#f9e3ae
  --bgClr-dark: rgba(3, 31, 48, 1); //#031f30
  --bgClr-darkest: rgba(14, 22, 27, 1); //#0e161b
  --bgClr-alert: rgba(255, 63, 115, 0.8);

  --txtClr-base: rgb(90, 103, 116, 1);
  --txtClr-accent: rgb(0, 63, 140, 1);
  --txtClr-white: rgb(255, 255, 255, 1); //#ffffff
  --txtClr-lightgray: rgba(235, 237, 238, 1); //#ebedee
  --txtClr-lightblue: rgb(196, 240, 255, 1); //#c4f0ff
  --txtClr-gold: rgb(249, 227, 174, 1); //#f9e3ae
  --txtClr-orange: rgba(247, 147, 26, 1); //#f7931a

  --accent-azureblue: rgb(36, 150, 237);
  //--accent-azureblue: rgba(0, 180, 255); //#00b4ff
  --accent-alert: rgba(255, 63, 115, 1);

  /* FONTS ANDS FONT SIZES */
  --ff: Poppins, "Open Sans", sans-serif;
  --ff-secondary: "Merriweather", serif;
  --fs-base: 1rem;
  --fs-xs: 0.625rem;
  --fs-sm: 0.75rem;
  --fs-base-plus: 1.125rem; //added in later versions
  --fs-md: 1.25rem;
  --fs-lg: 1.5rem;
  --fs-xl: 1.75rem;
  --fs-2xl: 2rem;
  --fs-3xl: 3rem;
  --fs-4xl: 4.5rem;
  --fs-5xl: 5.25rem;
  --fs-6xl: 6rem;
  --fs-8xl: 9rem;
  --fs-10xl: 12rem;

  /* PADDING */
  --pd-base: 2em;
  --pd-xs: 0.5em;
  --pd-sm: 1em;
  --pd-md: 2.5em;
  --pd-lg: 4em;
  --pd-xl: 6em;
  --pd-2xl: 8em;
  --pd-3xl: 12em;
  --pd-4xl: 16em;
  --pd-8xl: 32em;

  /* MARGINS */
  --mg-base: 2em;
  --mg-xs: 0.5em;
  --mg-sm: 1em;
  --mg-md: 2.5em;
  --mg-lg: 4em;
  --mg-xl: 6em;
  --mg-2xl: 8em;
  --mg-3xl: 12em;
  --mg-4xl: 16em;

  //set by layout.js
  --height-header: 125px;
  --height-highlights: 1125px;
  --height-menu: 510px;

  /* PSEUDO ELEM TXT VAR */
  /* Value can be by JS language translation */
  --pseudo_txt_copy: "Gekopieerd!";
  --pseudo_txt_no-js: "Schakel JavaScript in of gebruik een browser met JavaScript ondersteuning. Deze website werkt niet goed zonder JavaScript.";
}

* {
  box-sizing: border-box;
  margin: 0;
}
html,
body {
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;

  font-family: var(--ff);
  font-size: var(--fs-base);
  color: var(--txtClr-base);

  background-color: var(--bgClr);
  overflow-x: hidden;
}

ul {
  list-style-type: none;
  padding: 0;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4 {
  line-height: 1.25;
}

p {
  line-height: 1.75;
  margin-bottom: var(--mg-xs);

  &:last-of-type {
    margin-bottom: 0;
  }
}

a {
  display: inline-block;
  text-decoration: none;
  letter-spacing: 1px;
  transition: all 0.3s ease-in;

  &:hover {
    cursor: pointer;
  }
}

button {
  font-size: inherit;
  font-family: inherit;

  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
  }
}
