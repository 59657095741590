.about {
  /* receives base styles from __section-base */
  position: relative;

  &__nav-container {
    display: none;
    position: absolute;
    top: 0.5em;
    left: 0.5em;
  }

  &__nav-btn {
    font-size: var(--fs-xs);

    @include pc_lg {
      font-size: var(--fs-base);
    }
    @include pc_2xl {
      font-size: var(--fs-lg);
    }
  }

  &__content-container {
    @include tablet_md {
      max-width: 55ch;
      margin: 0 auto;
    }
  }

  &__heading {
    &__title {
      font-size: var(--fs-xl);
      text-align: center;
      margin-bottom: var(--mg-base);

      @include pc_lg {
        font-size: var(--fs-2xl);
      }
      @include pc_xl {
        font-size: var(--fs-3xl);
      }
      @include pc_2xl {
        font-size: var(--fs-6xl);
      }
    }

    &__subtitle {
      display: flex;
      flex-direction: column;

      text-align: center;

      margin-bottom: var(--mg-base);

      @include tablet_md {
        flex-direction: row;
        justify-content: flex-start;
      }

      p {
        @include tablet_md {
          align-self: center;
        }
        span {
          font-weight: bold;
        }
      }
    }

    &__image {
      width: 5.5em;
      margin: 0 auto var(--mg-base);

      @include tablet_md {
        margin: 0 var(--mg-base) 0 0;
      }
    }
  }

  &__txt-container {
    overflow: hidden;
    margin-bottom: var(--mg-sm);

    .highlight-first-char::first-letter {
      @include highlight-first-char;
    }
  }

  &__btn-modal {
    padding: 0;

    &__icon {
      margin-right: 0;
    }
  }

  &__btn-expand {
    border: 0.125em solid var(--txtClr-base);
    font-size: var(--fs-base);

    @include pc_lg {
      font-size: var(--fs-base-plus);
    }
    @include pc_xl {
      font-size: var(--fs-xl);
    }
    @include pc_2xl {
      font-size: var(--fs-3xl);
    }
  }
}

.expand {
  display: none;

  &--active {
    display: block;
    animation: slide-up 500ms forwards, fade-in 1000ms forwards;
  }
}
