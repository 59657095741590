@import "./modules/global";
@import "./modules/mixins";
@import "./modules/components/noscript";
@import "./modules/components/wrapper";
@import "./modules/components/buttons/btns";
@import "./modules/components/buttons/menu-toggle";
@import "./modules/components/buttons/lang-menu-toggle";
@import "./modules/components/icons/icon-group-container";
@import "./modules/components/icons/icon-info";
@import "./modules/components/text";
@import "./modules/components/animations";
@import "./modules/components/lang-menu";
@import "./modules/components/modal";
@import "./modules/components/txt-link";
@import "./modules/components/block-link";
@import "./modules/components/list-in-txt.scss";
@import "./modules/components/card.scss";
@import "./modules/components/horizontal-list";
@import "./modules/components/tooltip";
@import "./modules/components/easter-egg";
@import "./modules/components/scroll-indicator";
@import "./modules/components/scroll-down";
@import "./modules/components/consent";
@import "./modules/components/profile-image";
@import "./modules/components/divider";
@import "./modules/header";
@import "./modules/sections/_section-base";
@import "./modules/sections/hero";
@import "./modules/sections/intro";
@import "./modules/sections/cta-contact.scss";
@import "./modules/sections/cta-more.scss";
@import "./modules/sections/testimonials";
@import "./modules/sections/about";
@import "./modules/sections/services";
@import "./modules/sections/skill";
@import "./modules/sections/fun-zone";
@import "./modules/footer";
@import "./modules/error404";
@import "./modules/privacy";
