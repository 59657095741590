:root {
  --bgClr: #0b214a;
  --bgClr-accent: #003f8c;
  --bgClr-white: #fff;
  --bgClr-lightgray: #ebedee;
  --bgClr-lightblue: #c4f0ff;
  --bgClr-gold: rgba(249, 227, 174, .25);
  --bgClr-dark: #031f30;
  --bgClr-darkest: #0e161b;
  --bgClr-alert: rgba(255, 63, 115, .8);
  --txtClr-base: #5a6774;
  --txtClr-accent: #003f8c;
  --txtClr-white: #fff;
  --txtClr-lightgray: #ebedee;
  --txtClr-lightblue: #c4f0ff;
  --txtClr-gold: #f9e3ae;
  --txtClr-orange: #f7931a;
  --accent-azureblue: #2496ed;
  --accent-alert: #ff3f73;
  --ff: Poppins, "Open Sans", sans-serif;
  --ff-secondary: "Merriweather", serif;
  --fs-base: 1rem;
  --fs-xs: .625rem;
  --fs-sm: .75rem;
  --fs-base-plus: 1.125rem;
  --fs-md: 1.25rem;
  --fs-lg: 1.5rem;
  --fs-xl: 1.75rem;
  --fs-2xl: 2rem;
  --fs-3xl: 3rem;
  --fs-4xl: 4.5rem;
  --fs-5xl: 5.25rem;
  --fs-6xl: 6rem;
  --fs-8xl: 9rem;
  --fs-10xl: 12rem;
  --pd-base: 2em;
  --pd-xs: .5em;
  --pd-sm: 1em;
  --pd-md: 2.5em;
  --pd-lg: 4em;
  --pd-xl: 6em;
  --pd-2xl: 8em;
  --pd-3xl: 12em;
  --pd-4xl: 16em;
  --pd-8xl: 32em;
  --mg-base: 2em;
  --mg-xs: .5em;
  --mg-sm: 1em;
  --mg-md: 2.5em;
  --mg-lg: 4em;
  --mg-xl: 6em;
  --mg-2xl: 8em;
  --mg-3xl: 12em;
  --mg-4xl: 16em;
  --height-header: 125px;
  --height-highlights: 1125px;
  --height-menu: 510px;
  --pseudo_txt_copy: "Gekopieerd!";
  --pseudo_txt_no-js: "Schakel JavaScript in of gebruik een browser met JavaScript ondersteuning. Deze website werkt niet goed zonder JavaScript.";
}

* {
  box-sizing: border-box;
  margin: 0;
}

html, body {
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--ff);
  font-size: var(--fs-base);
  color: var(--txtClr-base);
  background-color: var(--bgClr);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

ul {
  padding: 0;
  list-style-type: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

h1, h2, h3, h4 {
  line-height: 1.25;
}

p {
  margin-bottom: var(--mg-xs);
  line-height: 1.75;
}

p:last-of-type {
  margin-bottom: 0;
}

a {
  letter-spacing: 1px;
  text-decoration: none;
  transition: all .3s ease-in;
  display: inline-block;
}

a:hover {
  cursor: pointer;
}

button {
  font-size: inherit;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  font-family: inherit;
}

button:hover {
  cursor: pointer;
}

noscript:before {
  content: var(--pseudo_txt_no-js);
  font-size: var(--fs-md);
  text-align: center;
  padding: var(--pd-sm) var(--pd-xs);
  background-color: var(--accent-alert);
  color: var(--txtClr-white);
  z-index: 7;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 48rem) {
  noscript:before {
    padding: var(--pd-sm) var(--pd-base);
  }
}

@media (min-width: 160rem) {
  noscript:before {
    padding: var(--pd-base) var(--pd-md);
    font-size: var(--fs-xl);
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .wrapper {
    max-width: 5120px;
    margin: 0 auto;
    overflow: hidden;
  }
}

.btn {
  font-size: var(--fs-sm);
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1px;
  white-space: nowrap;
  border: .125em solid var(--bgClr-accent);
  color: var(--txtClr-white);
  background-color: var(--bgClr-accent);
  padding: var(--pd-sm) var(--pd-base);
  border-radius: .3125em;
  font-weight: 600;
  box-shadow: .125em .125em .125em rgba(0, 0, 0, .25);
}

@media (min-width: 23.4375rem) {
  .btn {
    font-size: var(--fs-base);
  }
}

@media (min-width: 120rem) {
  .btn {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .btn {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .btn {
    font-size: var(--fs-4xl);
  }
}

.btn:hover {
  color: var(--accent-azureblue);
  background-color: var(--bgClr-white);
  border-color: var(--bgClr-white);
}

.btn-icon {
  white-space: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.btn-icon img {
  width: 1.25em;
  height: 1.25em;
}

.btn-icon:after {
  content: var(--pseudo_txt_copy);
  color: var(--txtClr-white);
  background-color: var(--accent-azureblue);
  padding: var(--pd-xs);
  border-radius: .3125em;
  display: none;
  position: absolute;
  top: -1.3125em;
  right: .1875em;
}

.btn-icon.js-copied:after {
  display: inline-block;
}

.btn--outline-blue {
  border-color: var(--bgClr-accent);
  background-color: rgba(0, 0, 0, 0);
}

.btn--outline-gray {
  color: var(--txtClr-base);
  border-color: var(--txtClr-base);
  background-color: rgba(0, 0, 0, 0);
}

.btn--outline-gray:hover {
  background-color: var(--txtClr-base);
  color: var(--txtClr-white);
  border-color: rgba(0, 0, 0, 0);
}

.btn--lightblue {
  color: var(--txtClr-base);
  background-color: var(--bgClr-lightblue);
  border-color: var(--txtClr-lightblue);
}

.btn--horizontal-list {
  white-space: normal;
  font-size: var(--fs-xs);
  padding: var(--pd-xs) var(--pd-sm);
  margin: 0 auto var(--mg-base);
  width: 15em;
}

@media (min-width: 23.4375rem) {
  .btn--horizontal-list {
    font-size: var(--fs-sm);
    padding: var(--pd-sm) var(--pd-base);
  }
}

@media (min-width: 120rem) {
  .btn--horizontal-list {
    font-size: var(--fs-base);
  }
}

@media (min-width: 160rem) {
  .btn--horizontal-list {
    font-size: var(--fs-base-plus);
  }
}

@media (min-width: 320rem) {
  .btn--horizontal-list {
    font-size: var(--fs-2xl);
  }
}

.btn-close {
  font-size: var(--fs-md);
  color: var(--txtClr-base);
  padding: 0;
  transition: all .3s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(-50%);
}

.btn-close:hover {
  color: var(--txtClr-white);
  cursor: pointer;
}

.btn-close--white {
  color: var(--txtClr-white);
}

.btn-close--white:hover {
  color: var(--txtClr-lightblue);
}

.btn-close--orange:hover {
  color: var(--txtClr-orange);
}

@media (min-width: 64rem) {
  .btn-close {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 120rem) {
  .btn-close {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .btn-close {
    font-size: var(--fs-6xl);
  }
}

.button {
  text-transform: capitalize;
  letter-spacing: 1px;
  color: var(--txtClr-base);
  border-radius: 5px;
  padding: .5em 1em;
  transition: all .5s;
}

.button:hover {
  cursor: pointer;
}

.menu-toggle {
  z-index: 3;
  width: 2.25em;
  height: 2.5em;
  position: relative;
}

.menu-toggle:hover {
  cursor: pointer;
}

.menu-toggle__toggle {
  border-bottom: .1875em solid var(--bgClr-gold);
  border-radius: .3125em;
  width: 100%;
  height: .1875em;
  transition: all .3s ease-in;
  position: absolute;
  bottom: 0;
  left: 0;
}

.menu-toggle__toggle:before, .menu-toggle__toggle:after {
  content: "";
  background-color: var(--bgClr-gold);
  border-radius: .3125em;
  width: 100%;
  height: .1875em;
  position: absolute;
  left: 0;
}

.menu-toggle__toggle:before {
  top: -.625em;
}

.menu-toggle__toggle:after {
  top: -1.25em;
}

.menu-toggle__toggle--active {
  border-bottom: none;
  animation: .5s forwards fade-in;
}

.menu-toggle__toggle--active:before {
  background-color: var(--txtClr-lightgray);
  transform: rotate(-45deg);
}

.menu-toggle__toggle--active:after {
  background-color: var(--txtClr-lightgray);
  top: -.625em;
  right: -.0625em;
  transform: rotate(45deg);
}

.menu-toggle__toggle--closing {
  animation: .5s forwards fade-out;
}

.lang-menu-toggle {
  z-index: 3;
  cursor: pointer;
  opacity: .7;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.lang-menu-toggle__icon {
  margin-right: var(--mg-xs);
  width: 1.25em;
  height: 1.25em;
}

.lang-menu-toggle__text {
  font-size: var(--fs-sm);
  color: var(--txtClr-lightblue);
}

@media (min-width: 120rem) {
  .lang-menu-toggle__text {
    font-size: var(--fs-base);
  }
}

@media (min-width: 160rem) {
  .lang-menu-toggle__text {
    font-size: var(--fs-md);
  }
}

@media (min-width: 320rem) {
  .lang-menu-toggle__text {
    font-size: var(--fs-3xl);
  }
}

.icon-group-container {
  margin: 0 auto var(--mg-base);
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-evenly;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 36rem) {
  .icon-group-container {
    max-width: 45ch;
  }
}

.icon-group-container img {
  max-width: 3em;
}

.icon-info {
  -ms-flex-item-align: center;
  align-self: center;
  width: 1em;
  height: 1em;
}

.icon-info--md {
  width: 1.125em;
  height: 1.125em;
}

@media (min-width: 48rem) {
  .icon-info--md {
    width: 1.5em;
    height: 1.5em;
  }
}

.icon-info--lg {
  width: 1.5em;
  height: 1.5em;
}

.text-emphasis {
  font-style: italic;
}

.text-emphasis--accent {
  color: var(--bgClr-accent);
  font-weight: 600;
}

.text-emphasis--underline {
  border-bottom: 6px solid var(--bgClr-accent);
  border-radius: 5px;
}

.text-emphasis--bg {
  border-radius: 2px;
  padding: .06em .12em;
  line-height: 1.66;
}

.text-emphasis--bg-accent {
  background-color: var(--bgClr-accent);
  color: #fff;
}

.text-emphasis--bg-white {
  color: var(--bgClr-accent);
  background-color: #fff;
}

.text-strong {
  color: var(--bgClr-accent);
}

#snowfall {
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }

  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }

  100% {
    opacity: 0;
    transform: translateY(4.8rem)scale(.5);
  }
}

@keyframes move-chevron--lg {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    transform: translateY(3.8rem);
  }

  66.6% {
    opacity: 1;
    transform: translateY(5.2rem);
  }

  100% {
    opacity: 0;
    transform: translateY(8rem)scale(.5);
  }
}

.language {
  z-index: 3;
  padding: var(--pd-xs);
  width: 100%;
  height: 100vh;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.language--active {
  animation: .5s fade-in;
  display: block;
}

.language--closing {
  animation: .5s fade-out;
}

.language__list {
  background-color: var(--bgClr-lightgray);
  padding: var(--pd-sm) var(--pd-sm);
  border-radius: 5px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-27%, 27%);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

@media (min-width: 48rem) {
  .language__list {
    transform: translate(-54%, 33%);
  }
}

@media (min-width: 120rem) {
  .language__list {
    font-size: var(--fs-xl);
    transform: translate(-33%, 27%);
  }
}

@media (min-width: 160rem) {
  .language__list {
    font-size: var(--fs-2xl);
    transform: translate(-45%, 42%);
  }
}

@media (min-width: 320rem) {
  .language__list {
    font-size: var(--fs-4xl);
    transform: translate(-69%, 45%);
  }
}

.language__list:before {
  content: "";
  border-style: solid;
  border-width: 0 .75em .75em;
  border-color: transparent transparent var(--bgClr-lightgray) transparent;
  width: 0;
  height: 0;
  position: absolute;
  top: -.6em;
  right: 12%;
}

.language__option__btn {
  border: none;
  width: 100%;
}

.language__option__btn:hover {
  color: #fff;
  background-color: #003f8c;
}

.modal {
  z-index: 6;
  padding-top: var(--pd-base);
  background-color: rgba(3, 31, 48, .3);
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.modal--active {
  animation: .5s forwards fade-in;
  display: block;
}

.modal--closing {
  animation: .5s forwards fade-out;
}

.modal__content-container {
  padding: var(--pd-base) var(--pd-sm);
  margin: 0 auto var(--mg-base);
  background-color: var(--bgClr-dark);
  color: var(--txtClr-gold);
  border-radius: 5px;
  width: 90%;
  position: relative;
  box-shadow: 5px 5px 5px 8em rgba(233, 235, 238, .6);
}

.txt-link {
  color: var(--txtClr-base);
  white-space: nowrap;
  -ms-flex-flow: row;
  flex-flow: row;
  text-decoration: underline;
  display: -ms-inline-flexbox;
  display: inline-flex;
  overflow: hidden;
}

.txt-link--lightblue {
  color: var(--txtClr-lightblue);
  text-decoration: none;
}

.txt-link--orange {
  color: var(--txtClr-orange);
  text-decoration: none;
}

.txt-link--accent {
  color: var(--bgClr-accent);
}

.txt-link__icon {
  -ms-flex-item-align: center;
  align-self: center;
  width: .9em;
  height: .9em;
  margin: 0 .375em;
}

.block-link {
  background-color: var(--bgClr-white);
  font-size: var(--fs-sm);
  padding: var(--pd-base) var(--pd-xs);
  margin-bottom: var(--mg-base);
  border-radius: 5px;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

@media (min-width: 48rem) {
  .block-link {
    padding: var(--pd-base) var(--pd-base);
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (min-width: 120rem) {
  .block-link {
    font-size: var(--fs-base);
  }
}

@media (min-width: 320rem) {
  .block-link {
    font-size: var(--fs-2xl);
  }
}

.block-link__image-container {
  margin-bottom: var(--mg-base);
}

@media (min-width: 48rem) {
  .block-link__image-container {
    margin: 0 var(--mg-sm) 0 0;
  }
}

.block-link__image {
  border-radius: 5px;
  width: 8.1em;
  margin: 0 auto;
}

@media (min-width: 48rem) {
  .block-link__image {
    width: 9.6em;
  }
}

@media (min-width: 320rem) {
  .block-link__image {
    width: 12em;
  }
}

.block-link__desc {
  padding: 0 var(--pd-xs);
  text-align: center;
}

@media (min-width: 23.4375rem) {
  .block-link__desc {
    padding: 0 var(--pd-sm);
  }
}

@media (min-width: 48rem) {
  .block-link__desc {
    text-align: left;
  }
}

.block-link__desc__text {
  margin-bottom: var(--mg-base) !important;
}

@media (min-width: 48rem) {
  .block-link__desc__text {
    margin-bottom: var(--mg-sm) !important;
  }
}

.block-link__desc__cta-container {
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 48rem) {
  .block-link__desc__cta-container {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.block-link__desc__icon {
  margin-right: var(--mg-sm);
  width: 2.4em;
}

.block-link__desc__cta {
  -ms-flex-item-align: center;
  align-self: center;
  text-decoration: underline;
}

.block-link__link {
  z-index: 5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.list-in-text {
  margin: var(--mg-xs) 0;
  list-style-type: disc;
  overflow: hidden;
}

.list-in-text li {
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  margin-left: 1em;
  margin-bottom: var(--mg-xs);
}

.list-in-text li::marker {
  color: var(--txtClr-lightblue);
}

.list-in-text li:last-of-type {
  margin-bottom: 0;
}

.card {
  font-size: var(--fs-base);
  text-align: center;
  color: var(--txtClr-base);
  background-color: var(--bgClr-white);
  padding: var(--pd-sm) var(--pd-xs);
  border-radius: 5px;
  width: 95%;
  margin: 0 auto;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

@media (min-width: 23.4375rem) {
  .card {
    padding: var(--pd-base) var(--pd-sm);
  }
}

@media (min-width: 48rem) {
  .card {
    padding: var(--pd-md) var(--pd-md);
  }
}

@media (min-width: 120rem) {
  .card {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 160rem) {
  .card {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .card {
    font-size: var(--fs-4xl);
  }
}

.card__title {
  font-size: var(--fs-base-plus);
  margin-bottom: var(--mg-sm);
}

@media (min-width: 120rem) {
  .card__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .card__title {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .card__title {
    font-size: var(--fs-5xl);
  }
}

.card__sub {
  line-height: 1.5;
  margin-bottom: var(--mg-sm) !important;
}

.card__image {
  margin-bottom: var(--mg-base);
  border-radius: 5px;
}

.card__btn {
  font-size: var(--fs-sm);
  width: 15em;
  margin: 0 auto;
}

@media (min-width: 120rem) {
  .card__btn {
    font-size: var(--fs-base);
  }
}

@media (min-width: 160rem) {
  .card__btn {
    font-size: var(--fs-md);
  }
}

@media (min-width: 320rem) {
  .card__btn {
    font-size: var(--fs-3xl);
  }
}

.card__list {
  text-align: left;
  padding: 0;
}

.card__list__item {
  margin-bottom: var(--mg-base);
}

.card__list__item:last-child {
  margin-bottom: 0;
}

.horizontal-list {
  background-color: var(--bgClr-lightgray);
  padding: var(--pd-sm);
  scrollbar-color: var(--accent-azureblue) var(--bgClr-white);
  scrollbar-width: none;
  border-radius: 5px;
  width: auto;
  overflow-x: auto;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35), inset -33px 0 33px -33px rgba(0, 0, 0, .35);
}

@media (min-width: 36rem) {
  .horizontal-list {
    padding: var(--pd-base);
  }
}

@media (min-width: 64rem) {
  .horizontal-list {
    overflow-x: none;
    padding: var(--pd-md);
    width: 78ch;
    margin: 0 auto;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
  }
}

.horizontal-list__list {
  grid-gap: var(--mg-sm);
  grid-template-columns: repeat(4, auto);
  display: grid;
}

@media (min-width: 64rem) {
  .horizontal-list__list {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
  }
}

.horizontal-list__item {
  text-align: center;
  color: var(--txtClr-base);
  padding: var(--pd-sm) var(--pd-xs);
  border-radius: 5px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

@media (min-width: 48rem) {
  .horizontal-list__item {
    padding: var(--pd-base) var(--pd-sm);
  }
}

.horizontal-list__item:last-of-type:after {
  content: "";
  top: 0;
  right: calc(-1 * var(--mg-base));
  width: var(--mg-base);
  height: 100%;
  position: absolute;
}

.horizontal-list__item__content-container {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
}

.horizontal-list__item__title {
  font-size: var(--fs-base);
  margin-bottom: var(--mg-sm);
}

@media (min-width: 36rem) {
  .horizontal-list__item__title {
    font-size: var(--fs-base-plus);
  }
}

@media (min-width: 120rem) {
  .horizontal-list__item__title {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .horizontal-list__item__title {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .horizontal-list__item__title {
    font-size: var(--fs-4xl);
  }
}

.horizontal-list__item__image-container {
  margin-bottom: var(--mg-sm);
  height: 45%;
}

@media (min-width: 64rem) {
  .horizontal-list__item__image-container {
    margin-bottom: var(--mg-base);
  }
}

.horizontal-list__item__image {
  object-fit: cover;
  border-radius: 5px;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  height: 100%;
}

.horizontal-list__item__link-container {
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.horizontal-list__item__tooltip {
  -ms-flex-item-align: center;
  align-self: center;
}

@media (min-width: 64rem) {
  .horizontal-list {
    scrollbar-width: auto;
  }
}

.horizontal-list::-webkit-scrollbar {
  background-color: var(--bgClr-accent);
  height: .66em;
  display: none;
}

@media (min-width: 64rem) {
  .horizontal-list::-webkit-scrollbar {
    display: block;
  }
}

.horizontal-list::-webkit-scrollbar-track {
  background-color: var(--bgClr-white);
  border-radius: 0 0 5px 5px;
}

.horizontal-list::-webkit-scrollbar-button {
  display: none;
}

.horizontal-list::-webkit-scrollbar-thumb {
  background-color: var(--accent-azureblue);
  border: 2px solid var(--bgClr-white);
  border-radius: 5px;
}

.tooltip {
  z-index: 6;
  font-size: var(--fs-sm);
  background-color: rgba(196, 240, 255, .75);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.tooltip--active {
  animation: .5s forwards fade-in;
  display: block;
}

.tooltip--closing {
  animation: .5s forwards fade-out;
  display: block;
}

.tooltip__content-container {
  background-color: var(--bgClr-dark);
  color: var(--txtClr-white);
  padding: var(--pd-md);
  border-radius: 5px;
  width: 85%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 48rem) {
  .tooltip__content-container {
    max-width: 55ch;
  }
}

@media (min-width: 64rem) {
  .tooltip__content-container {
    font-size: var(--fs-base);
    padding: var(--pd-base) var(--pd-base);
  }
}

@media (min-width: 120rem) {
  .tooltip__content-container {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .tooltip__content-container {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .tooltip__content-container {
    font-size: var(--fs-3xl);
  }
}

.tooltip__text:last-of-type {
  margin-bottom: var(--mg-sm);
}

.easter-egg {
  display: none;
}

.easter-egg--visible {
  z-index: 5;
  width: 25%;
  display: block;
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.scroll-indicator {
  background-color: var(--bgClr-white);
  padding: var(--pd-sm);
  z-index: 5;
  opacity: 0;
  border-radius: 50%;
  position: fixed;
  bottom: .5em;
  right: .5em;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

@media (min-width: 320rem) {
  .scroll-indicator {
    font-size: var(--fs-2xl);
  }
}

.scroll-indicator:hover {
  cursor: pointer;
}

.scroll-indicator.reveal {
  opacity: 1;
}

.scroll-indicator__arrow {
  z-index: 1;
  height: 1.25em;
}

.scroll-indicator__bg {
  z-index: -1;
  background-color: #00b4ff;
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.scroll-down-indicator {
  opacity: 1;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  transition: opacity .6s ease-in-out;
  display: -ms-flexbox;
  display: flex;
}

.scroll-down-indicator.hide {
  opacity: 0;
}

.chevron {
  opacity: 0;
  width: 2.1rem;
  height: .48rem;
  animation: 3s ease-out infinite move-chevron;
  position: absolute;
  transform: scale(.3);
}

@media (min-width: 120rem) {
  .chevron {
    width: 3.5rem;
    height: .8rem;
    animation: 3s ease-out infinite move-chevron--lg;
  }
}

@media (min-width: 320rem) {
  .chevron {
    width: 7rem;
    height: 1.6rem;
  }
}

.chevron:first-child {
  animation: 3s ease-out 1s infinite move-chevron;
}

.chevron:nth-child(2) {
  animation: 3s ease-out 2s infinite move-chevron;
}

.chevron:before, .chevron:after {
  content: "";
  background: #2c3e50;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  width: 50%;
  right: 0;
  transform: skewY(-30deg);
}

.consent {
  visibility: hidden;
  z-index: 5;
  background-color: var(--bgClr-dark);
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.consent--active {
  visibility: visible;
  display: -ms-flexbox;
  display: flex;
}

.consent--closing {
  animation: .5s forwards fade-out;
}

.consent__content-container {
  padding: var(--pd-sm);
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 48rem) {
  .consent__content-container {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (min-width: 120rem) {
  .consent__content-container {
    font-size: var(--fs-md);
  }
}

@media (min-width: 320rem) {
  .consent__content-container {
    font-size: var(--fs-2xl);
  }
}

.consent__message {
  text-align: center;
  color: var(--txtClr-white);
  margin: 0 0 var(--mg-base) !important;
}

@media (min-width: 48rem) {
  .consent__message {
    text-align: left;
    margin: 0 var(--mg-base) 0 0 !important;
  }
}

.consent__btn {
  font-size: var(--fs-sm);
  margin-bottom: var(--mg-sm);
  width: 18em;
}

@media (min-width: 48rem) {
  .consent__btn {
    margin-bottom: unset;
  }

  .consent__btn:first-of-type {
    margin-left: var(--mg-sm);
    -ms-flex-order: 2;
    order: 2;
  }
}

@media (min-width: 120rem) {
  .consent__btn {
    font-size: var(--fs-base);
  }
}

@media (min-width: 160rem) {
  .consent__btn {
    font-size: var(--fs-md);
  }
}

@media (min-width: 320rem) {
  .consent__btn {
    font-size: var(--fs-xl);
  }
}

.profile-image {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

.profile-image__image {
  width: 100%;
}

.divider {
  margin-bottom: var(--mg-xs);
  background-color: #00b4ff;
  border: none;
  border-radius: 5px;
  width: 6em;
  height: 6px;
}

@media (min-width: 320rem) {
  .divider {
    height: 18px;
  }
}

.header {
  z-index: 3;
  position: relative;
}

.header__content-container {
  padding: var(--pd-sm);
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 48rem) {
  .header__content-container {
    padding: var(--pd-base) var(--pd-lg) var(--pd-sm);
  }
}

@media (min-width: 120rem) {
  .header__content-container {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .header__content-container {
    font-size: var(--fs-xl);
    padding: var(--pd-lg) var(--pd-lg) var(--pd-sm);
  }
}

@media (min-width: 320rem) {
  .header__content-container {
    font-size: var(--fs-4xl);
    padding: var(--pd-lg) var(--pd-xl) var(--pd-sm);
  }
}

.header__logo {
  z-index: 3;
  width: 5.4em;
  position: relative;
}

.header__logo__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.header__logo__svg {
  width: 100%;
  height: 100%;
}

.header__right {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
}

.header__right button:first-child {
  margin: var(--mg-sm) var(--mg-xs) 0 0;
}

.menu {
  z-index: 2;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.menu--active {
  animation: .5s forwards fade-in;
  display: block;
}

.menu--closing {
  animation: .5s forwards fade-out;
}

.menu__nav {
  background-color: var(--txtClr-accent);
  border-bottom: 6px solid var(--accent-azureblue);
  color: var(--txtClr-white);
  padding: var(--pd-2xl) var(--pd-xs) var(--pd-sm);
  border-radius: 5px;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 120rem) {
  .menu__nav {
    padding: var(--pd-2xl) var(--pd-sm) var(--pd-base);
  }
}

@media (min-width: 160rem) {
  .menu__nav {
    padding: var(--pd-3xl) var(--pd-base) var(--pd-md);
  }
}

.menu__item {
  margin-bottom: var(--mg-sm);
  text-align: center;
  text-transform: capitalize;
  width: 18em;
}

.menu__item--alt .menu__item__link {
  border: none;
}

.menu__item__link {
  color: var(--txtClr-white);
  border: var(--bgClr-white) solid 1px;
  padding: var(--pd-sm) var(--pd-base);
  border-radius: 5px;
  width: 100%;
  transition: all .3s ease-in-out;
}

.menu__item__link:hover {
  background-color: var(--bgClr-white);
  color: var(--txtClr-accent);
}

.menu__item__snow-animation-switch span {
  color: var(--txtClr-white);
}

.menu__item__snow-animation-switch input:checked + label {
  background: var(--bgClr);
}

.section {
  font-size: var(--fs-base-plus);
  padding: var(--pd-xl) var(--pd-xs);
  margin: 0 auto var(--mg-2xl);
  border-radius: 5px;
  width: 95%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

.section--bgLightblue {
  background-color: var(--bgClr-lightblue);
  color: var(--bgClr-accent);
}

.section--bgLightgray {
  background-color: var(--bgClr-lightgray);
  color: var(--txtClr-base);
}

@media (min-width: 23.4375rem) {
  .section {
    padding: var(--pd-xl) var(--pd-sm);
  }
}

@media (min-width: 48rem) {
  .section {
    padding: var(--pd-xl) var(--pd-md);
  }
}

@media (min-width: 120rem) {
  .section {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .section {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .section {
    font-size: var(--fs-4xl);
  }
}

.section__title {
  font-size: var(--fs-xl);
  text-align: center;
  margin-bottom: var(--mg-base);
}

@media (min-width: 120rem) {
  .section__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .section__title {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .section__title {
    font-size: var(--fs-6xl);
  }
}

.hero {
  margin-top: calc(-1 * var(--height-header));
  width: 100%;
  height: 100vh;
  position: relative;
  top: 0;
}

@media screen and (orientation: landscape) {
  .hero {
    min-height: 450px;
  }
}

.hero__content-container {
  z-index: 1;
  padding: 0 var(--pd-sm);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 23.4375rem) {
  .hero__content-container {
    padding: 0 var(--pd-base);
  }
}

@media (min-width: 36rem) {
  .hero__content-container {
    padding: 0 var(--pd-lg);
  }
}

@media (min-width: 48rem) {
  .hero__content-container {
    padding: 0 var(--pd-2xl);
  }
}

@media (min-width: 120rem) {
  .hero__content-container {
    padding: 0 var(--pd-4xl);
  }
}

@media (min-width: 160rem) {
  .hero__content-container {
    padding: 0 var(--pd-8xl);
  }
}

@media (min-width: 320rem) {
  .hero__content-container {
    margin-top: var(--mg-4xl);
  }
}

.hero__text-container {
  text-align: center;
  margin: 0 auto var(--mg-lg);
  width: 100%;
}

@media (min-width: 160rem) {
  .hero__text-container {
    margin: 0 auto var(--mg-2xl);
  }
}

@media (min-width: 320rem) {
  .hero__text-container {
    margin: 0 auto var(--mg-4xl);
  }
}

.hero__typewriter {
  font-size: var(--fs-xl);
  color: var(--txtClr-lightblue);
  margin-bottom: var(--mg-xs);
}

@media (min-width: 23.4375rem) {
  .hero__typewriter {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 26.5625rem) {
  .hero__typewriter {
    font-size: 2.5rem;
  }
}

@media (min-width: 48rem) {
  .hero__typewriter {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 120rem) {
  .hero__typewriter {
    font-size: var(--fs-4xl);
  }
}

@media (min-width: 160rem) {
  .hero__typewriter {
    font-size: var(--fs-6xl);
  }
}

@media (min-width: 320rem) {
  .hero__typewriter {
    font-size: var(--fs-10xl);
  }
}

.hero__title {
  color: var(--txtClr-white);
  font-size: var(--fs-md);
  text-shadow: 2px 2px rgba(0, 0, 0, .1);
  margin: 0 0 var(--mg-xs);
}

@media (min-width: 23.4375rem) {
  .hero__title {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 26.5625rem) {
  .hero__title {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 64rem) {
  .hero__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 120rem) {
  .hero__title {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 160rem) {
  .hero__title {
    font-size: var(--fs-4xl);
  }
}

@media (min-width: 320rem) {
  .hero__title {
    font-size: var(--fs-8xl);
  }
}

.hero__subtitle {
  color: var(--txtClr-white);
  font-size: var(--fs-base);
  text-shadow: 2px 2px rgba(0, 0, 0, .1);
  padding: 0 var(--pd-base);
  margin: 0 auto;
  font-weight: 300;
  line-height: 1.5;
}

@media (min-width: 23.4375rem) {
  .hero__subtitle {
    font-size: var(--fs-base-plus);
  }
}

@media (min-width: 26.5625rem) {
  .hero__subtitle {
    font-size: var(--fs-md);
  }
}

@media (min-width: 64rem) {
  .hero__subtitle {
    font-size: var(--fs-lg);
    max-width: 55ch;
  }
}

@media (min-width: 120rem) {
  .hero__subtitle {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .hero__subtitle {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .hero__subtitle {
    font-size: var(--fs-6xl);
    padding: 0 var(--pd-xl);
  }
}

.hero__link-container {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

@media (min-width: 64rem) {
  .hero__link-container {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 120rem) {
  .hero__link-container {
    margin-bottom: var(--mg-lg);
  }
}

@media (min-width: 320rem) {
  .hero__link-container {
    margin-bottom: var(--mg-2xl);
  }
}

.hero__link {
  width: 18em;
}

.hero__link:first-of-type {
  margin-bottom: var(--mg-sm);
}

@media (min-width: 64rem) {
  .hero__link:first-of-type {
    margin-left: var(--mg-sm);
    margin-bottom: unset;
  }
}

.hero__bg-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (orientation: landscape) {
  .hero__bg-container {
    min-height: 450px;
  }
}

.hero__bg__overlay {
  z-index: -1;
  background-color: rgba(11, 33, 74, .93);
  width: 100%;
  height: 100%;
}

.hero__bg__svg {
  z-index: -2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.intro {
  border-radius: 0;
  width: 100%;
  margin-bottom: 0;
}

@media (min-width: 48rem) {
  .intro {
    padding: 0;
  }
}

.intro__highlight {
  z-index: 2;
  margin: 0 auto var(--mg-lg);
  position: relative;
}

@media (min-width: 48rem) {
  .intro__highlight {
    width: 100%;
    margin: 0 auto;
  }

  .intro__highlight__position-container {
    height: calc(var(--height-highlights) / 2);
    background-color: var(--bgClr);
    width: 100%;
    display: block;
  }
}

.intro__highlight__content-container {
  background-color: var(--bgClr-white);
  padding: var(--pd-md) var(--pd-xs);
  border-radius: 5px;
  margin: 0 auto;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .1);
}

@media (min-width: 23.4375rem) {
  .intro__highlight__content-container {
    padding: var(--pd-base) var(--pd-sm);
  }
}

@media (min-width: 36rem) {
  .intro__highlight__content-container {
    padding: var(--pd-lg) var(--pd-md);
  }
}

@media (min-width: 48rem) {
  .intro__highlight__content-container {
    padding: var(--pd-lg) var(--pd-md);
    width: 95%;
  }
}

.intro__highlight__title {
  font-size: var(--fs-lg);
  text-transform: uppercase;
  text-align: center;
  margin-bottom: var(--mg-md);
  font-weight: 600;
}

@media (min-width: 64rem) {
  .intro__highlight__title {
    margin-bottom: var(--mg-lg);
  }
}

@media (min-width: 120rem) {
  .intro__highlight__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .intro__highlight__title {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .intro__highlight__title {
    font-size: var(--fs-6xl);
  }
}

.intro__highlight__item-container {
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-evenly;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 64rem) {
  .intro__highlight__item-container {
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-evenly;
  }
}

.intro__highlight__item {
  text-align: center;
  margin: 0 0 var(--mg-lg);
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
}

.intro__highlight__item:last-of-type {
  margin: 0;
}

@media (min-width: 48rem) {
  .intro__highlight__item {
    margin: 0 auto var(--mg-lg);
    max-width: 55ch;
  }

  .intro__highlight__item:last-of-type {
    margin: 0;
  }
}

@media (min-width: 64rem) {
  .intro__highlight__item {
    margin: 0 var(--mg-base) 0 0;
    max-width: 45ch;
  }

  .intro__highlight__item:last-of-type {
    margin: 0;
  }
}

.intro__highlight__item__icon {
  margin-bottom: var(--mg-base);
  -ms-flex-item-align: center;
  align-self: center;
  width: 5.5em;
}

.intro__highlight__item__text {
  padding: 0;
}

.intro__main {
  max-width: 55ch;
  margin: 0 auto;
}

@media (min-width: 48rem) {
  .intro__main {
    padding-top: calc(var(--height-highlights) / 2 + var(--pd-lg));
    padding-left: var(--pd-xs);
    padding-bottom: var(--pd-xl);
  }
}

.intro__main__text:first-of-type {
  margin-bottom: var(--pd-lg);
}

.intro__main__image-container {
  margin: 0 auto var(--pd-lg);
  width: 47%;
}

@media (min-width: 36rem) {
  .intro__main__image-container {
    width: 33%;
  }
}

.cta-contact {
  text-align: center;
  font-size: var(--fs-base-plus);
  padding: var(--pd-xl) var(--pd-xs);
  margin: 0 auto;
}

@media (min-width: 23.4375rem) {
  .cta-contact {
    padding: var(--pd-xl) var(--pd-sm);
  }
}

@media (min-width: 120rem) {
  .cta-contact {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 160rem) {
  .cta-contact {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .cta-contact {
    font-size: var(--fs-4xl);
  }
}

.cta-contact__content-container {
  max-width: 45ch;
  margin: 0 auto;
}

.cta-contact__heading {
  margin-bottom: var(--mg-sm);
}

.cta-contact__heading__title {
  font-size: var(--fs-xl);
  color: var(--txtClr-white);
}

@media (min-width: 120rem) {
  .cta-contact__heading__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .cta-contact__heading__title {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .cta-contact__heading__title {
    font-size: var(--fs-6xl);
  }
}

.cta-contact__text {
  color: var(--txtClr-lightblue);
  margin-bottom: var(--mg-lg);
}

.cta-contact__link-container {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.cta-contact__link {
  width: 18em;
}

@media (min-width: 160rem) {
  .cta-contact__link {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .cta-contact__link {
    font-size: var(--fs-3xl);
  }
}

.cta-contact__link:first-of-type {
  margin-bottom: var(--mg-base);
}

.cta-more {
  font-size: var(--fs-base-plus);
  text-align: center;
  color: var(--bgClr-accent);
  background-color: var(--bgClr-lightblue);
  padding: var(--pd-xl) var(--pd-sm);
}

@media (min-width: 120rem) {
  .cta-more {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 160rem) {
  .cta-more {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .cta-more {
    font-size: var(--fs-3xl);
  }
}

.cta-more__text-container {
  margin: 0 auto var(--pd-base);
  max-width: 55ch;
}

.cta-more__title {
  font-size: var(--fs-xl);
  margin-bottom: var(--mg-sm);
}

@media (min-width: 160rem) {
  .cta-more__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .cta-more__title {
    font-size: var(--fs-4xl);
  }
}

@media (min-width: 160rem) {
  .cta-more__btn__link {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 320rem) {
  .cta-more__btn__link {
    font-size: var(--fs-3xl);
  }
}

.testimonials {
  font-size: var(--fs-base-plus);
  background-color: var(--bgClr-accent);
  padding: var(--pd-xl) var(--pd-xs);
}

@media (min-width: 120rem) {
  .testimonials {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 160rem) {
  .testimonials {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .testimonials {
    font-size: var(--fs-3xl);
  }
}

.testimonials__title {
  text-align: center;
  font-size: var(--fs-xl);
  color: var(--txtClr-white);
  margin: 0 auto var(--mg-base);
  max-width: 45ch;
}

@media (min-width: 120rem) {
  .testimonials__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .testimonials__title {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .testimonials__title {
    font-size: var(--fs-6xl);
  }
}

.project {
  background-color: var(--bgClr-lightgray);
  padding: var(--pd-base) var(--pd-sm);
  margin: 0 auto var(--mg-lg);
  border-radius: 5px;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
  box-shadow: 5px 5px 25px rgba(0, 0, 0, .25);
}

@media (min-width: 48rem) {
  .project {
    padding: var(--pd-base) var(--pd-xl);
  }
}

@media (min-width: 64rem) {
  .project {
    grid-column-gap: var(--mg-lg);
    grid-row-gap: var(--mg-xs);
    padding: var(--pd-md) var(--pd-xl);
    grid-template-columns: 40% 60%;
    display: grid;
  }

  .project:nth-of-type(2n) {
    grid-template-columns: 60% 40%;
  }

  .project:nth-of-type(2n) .project__desc, .project:nth-of-type(2n) .project__link-container {
    grid-column: 2;
  }

  .project:nth-of-type(2n) .project__demo {
    grid-column: 1;
  }
}

.project:last-child {
  margin-bottom: 0;
}

.project__demo {
  margin-bottom: var(--mg-base);
}

@media (min-width: 64rem) {
  .project__demo {
    grid-column: 2;
    -ms-flex-item-align: center;
    align-self: center;
    margin-bottom: 0;
  }
}

.project__demo__image, .project__demo__video {
  margin-bottom: var(--mg-xs);
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, .25);
}

.project__demo__video {
  width: 100%;
}

.project__demo__caption {
  text-align: center;
  font-size: var(--fs-sm);
}

.project__desc {
  margin-bottom: var(--mg-base);
}

@media (min-width: 64rem) {
  .project__desc {
    grid-row: 1;
    -ms-flex-item-align: center;
    align-self: center;
  }
}

.project__desc__logo {
  text-align: center;
  margin-bottom: var(--mg-sm);
}

.project__desc__logo__image {
  border-radius: 5px;
  width: 54%;
  margin: 0 auto;
}

@media (min-width: 26.5625rem) {
  .project__desc__logo__image {
    width: 12em;
  }
}

@media (min-width: 64rem) {
  .project__desc__logo__image {
    width: 9em;
  }
}

.project__desc__text {
  text-align: center;
  max-width: 33ch;
  margin: 0 auto;
}

@media (min-width: 64rem) {
  .project__link-container {
    grid-row: 2;
  }
}

.project__link {
  font-size: var(--fs-sm);
  width: 81%;
  margin: 0 auto;
}

.project__link:first-of-type {
  margin-bottom: var(--mg-sm);
}

@media (min-width: 23.4375rem) {
  .project__link {
    width: 18em;
  }
}

@media (min-width: 120rem) {
  .project__link {
    font-size: var(--fs-base);
  }
}

@media (min-width: 160rem) {
  .project__link {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 320rem) {
  .project__link {
    font-size: var(--fs-2xl);
  }
}

.about {
  position: relative;
}

.about__nav-container {
  display: none;
  position: absolute;
  top: .5em;
  left: .5em;
}

.about__nav-btn {
  font-size: var(--fs-xs);
}

@media (min-width: 120rem) {
  .about__nav-btn {
    font-size: var(--fs-base);
  }
}

@media (min-width: 320rem) {
  .about__nav-btn {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 48rem) {
  .about__content-container {
    max-width: 55ch;
    margin: 0 auto;
  }
}

.about__heading__title {
  font-size: var(--fs-xl);
  text-align: center;
  margin-bottom: var(--mg-base);
}

@media (min-width: 120rem) {
  .about__heading__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .about__heading__title {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .about__heading__title {
    font-size: var(--fs-6xl);
  }
}

.about__heading__subtitle {
  text-align: center;
  margin-bottom: var(--mg-base);
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 48rem) {
  .about__heading__subtitle {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .about__heading__subtitle p {
    -ms-flex-item-align: center;
    align-self: center;
  }
}

.about__heading__subtitle p span {
  font-weight: bold;
}

.about__heading__image {
  margin: 0 auto var(--mg-base);
  width: 5.5em;
}

@media (min-width: 48rem) {
  .about__heading__image {
    margin: 0 var(--mg-base) 0 0;
  }
}

.about__txt-container {
  margin-bottom: var(--mg-sm);
  overflow: hidden;
}

.about__txt-container .highlight-first-char:first-letter {
  text-transform: uppercase;
  float: left;
  margin-right: 5px;
  font-family: Monaco, Helvetica Neue;
  font-size: 4em;
  font-weight: 600;
  line-height: .85;
}

@media (min-width: 320rem) {
  .about__txt-container .highlight-first-char:first-letter {
    margin-right: 20px;
  }
}

.about__btn-modal {
  padding: 0;
}

.about__btn-modal__icon {
  margin-right: 0;
}

.about__btn-expand {
  border: .125em solid var(--txtClr-base);
  font-size: var(--fs-base);
}

@media (min-width: 120rem) {
  .about__btn-expand {
    font-size: var(--fs-base-plus);
  }
}

@media (min-width: 160rem) {
  .about__btn-expand {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .about__btn-expand {
    font-size: var(--fs-3xl);
  }
}

.expand {
  display: none;
}

.expand--active {
  animation: .5s forwards slide-up, 1s forwards fade-in;
  display: block;
}

.services__heading__image-container {
  margin: 0 auto var(--mg-md);
  width: 9em;
}

.services__list-container {
  text-align: center;
  margin: 0 auto var(--mg-lg);
  max-width: 55ch;
}

.services__list__item {
  margin-bottom: var(--mg-base);
}

.services__list__item__title {
  font-weight: 600;
  line-height: 1.5;
  font-size: var(--fs-md);
}

@media (min-width: 120rem) {
  .services__list__item__title {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 160rem) {
  .services__list__item__title {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .services__list__item__title {
    font-size: var(--fs-5xl);
  }
}

.services__addendum {
  max-width: 75ch;
  margin: 0 auto;
}

.skill-section {
  color: var(--txtClr-white);
  background-color: #003f8c;
}

.skill-section__heading {
  position: relative;
}

@media (min-width: 48rem) {
  .skill-section__heading {
    margin: 0 auto var(--mg-lg);
  }
}

.skill-section__heading__subtitle {
  text-align: center;
  font-style: italic;
  font-size: var(--fs-md);
  font-weight: 300;
  margin: calc(-1 * var(--mg-base)) 0 var(--mg-base) !important;
}

@media (min-width: 120rem) {
  .skill-section__heading__subtitle {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 160rem) {
  .skill-section__heading__subtitle {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .skill-section__heading__subtitle {
    font-size: var(--fs-4xl);
  }
}

.skill-section__nav-container {
  min-height: var(--height-menu);
  margin-bottom: var(--mg-xl);
}

.skill-section__nav {
  padding: var(--pd-base) var(--pd-xs);
  border-radius: 5px;
  width: 100%;
  transition: all .5s ease-in;
  position: relative;
  top: 0;
}

@media (min-width: 48rem) {
  .skill-section__nav {
    max-width: 50%;
    margin: 0 auto;
  }
}

.skill-section__nav ul {
  text-align: center;
  padding: 0;
}

.skill-section__nav ul li {
  margin-bottom: var(--mg-xs);
  display: inline-block;
}

.skill-section__nav ul li a {
  font-size: var(--fs-base);
  letter-spacing: .08em;
  text-transform: uppercase;
  text-transform: capitalize;
  word-wrap: break-word;
  color: var(--txtClr-white);
  background-color: var(--bgClr-gold);
  padding: var(--pd-sm) var(--pd-base);
  border-radius: 5px;
  width: 14em;
  transform: scale(1);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, .35);
}

.skill-section__nav ul li a:hover {
  color: var(--txtClr-lightblue);
  transform: scale(1.025);
}

@media (min-width: 23.4375rem) {
  .skill-section__nav ul li a {
    width: 18em;
  }
}

@media (min-width: 36rem) {
  .skill-section__nav ul li a {
    width: 21em;
  }
}

@media (min-width: 120rem) {
  .skill-section__nav ul li a {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .skill-section__nav ul li a {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .skill-section__nav ul li a {
    font-size: var(--fs-3xl);
  }
}

.skill-section__nav__onScroll {
  top: unset;
  max-width: unset;
  z-index: 5;
  padding: var(--pd-sm) var(--pd-xs);
  background-color: rgba(3, 31, 48, .95);
  border-radius: 5px 5px 0 0;
  margin-bottom: 0;
  position: fixed;
  bottom: 0;
  left: 0;
}

@media (min-width: 64rem) {
  .skill-section__nav__onScroll {
    background-color: rgba(11, 33, 74, .95);
  }
}

.skill-section__nav__onScroll ul li {
  display: none;
}

.skill-section__nav__onScroll ul li.active {
  display: inline-block;
}

.skill-section__nav__onScroll ul li.active a {
  font-size: var(--fs-sm);
  padding: var(--pd-xs) var(--pd-sm);
  color: var(--txtClr-gold);
  background-color: var(--bgClr-gold);
}

@media (min-width: 64rem) {
  .skill-section__nav__onScroll ul li {
    display: inline-block;
  }

  .skill-section__nav__onScroll ul li a {
    font-size: var(--fs-sm);
    padding: var(--pd-xs) var(--pd-sm);
    color: var(--txtClr-gold);
  }

  .skill-section__nav__onScroll ul li.active a {
    background-color: unset;
  }
}

@media (min-width: 120rem) {
  .skill-section__nav__onScroll ul li a, .skill-section__nav__onScroll ul li.active a {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .skill-section__nav__onScroll ul li a, .skill-section__nav__onScroll ul li.active a {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .skill-section__nav__onScroll ul li a, .skill-section__nav__onScroll ul li.active a {
    font-size: var(--fs-2xl);
  }
}

.article {
  margin-bottom: var(--mg-xl);
  overflow: hidden;
}

.article:last-of-type {
  margin-bottom: 0;
}

@media (min-width: 48rem) {
  .article {
    margin: 0 auto var(--mg-2xl);
  }
}

.article__heading {
  text-align: center;
  word-break: break-word;
  margin-bottom: var(--mg-md);
}

.article__title {
  margin-bottom: var(--mg-xs);
}

.article__title--mg-base {
  margin-bottom: var(--mg-sm);
}

.article__subtitle {
  font-style: italic;
  font-weight: 300;
}

.article__main {
  max-width: 78ch;
  margin: 0 auto;
}

.article__topic {
  margin-bottom: var(--mg-base);
}

.article__topic__title {
  margin-bottom: var(--mg-xs);
}

.article__card {
  margin: 0 auto var(--mg-base);
}

.article__card:last-of-type {
  margin-bottom: 0;
}

.fun-zone {
  overflow: hidden;
}

.fun-zone__content-container {
  position: relative;
}

.fun-zone__heading {
  text-align: center;
  margin-bottom: var(--mg-lg);
}

.fun-zone__heading__subtitle {
  font-size: var(--fs-base-plus);
  max-width: 55ch;
  margin: -2em auto 0;
  font-style: italic;
  font-weight: 300;
}

@media (min-width: 120rem) {
  .fun-zone__heading__subtitle {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 160rem) {
  .fun-zone__heading__subtitle {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .fun-zone__heading__subtitle {
    font-size: var(--fs-4xl);
  }
}

.footer {
  color: var(--accent-azureblue);
  background-color: var(--bgClr-darkest);
  padding: var(--pd-xl) var(--pd-base) 0;
  border-top: 6px solid #00b4ff;
  position: relative;
}

@media (min-width: 120rem) {
  .footer {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 160rem) {
  .footer {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .footer {
    font-size: var(--fs-4xl);
  }
}

.footer__snow-animation-switch, .footer__company-info {
  margin-bottom: var(--mg-lg);
}

.footer__company-info__logo {
  margin: 0 auto var(--mg-sm);
  width: 9em;
  position: relative;
}

.footer__company-info__logo__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.footer__company-info__content-container {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.footer__company-info__num {
  text-transform: capitalize;
  color: var(--txtClr-white);
}

.footer__company-info__icon {
  margin-right: var(--mg-xs);
  width: 1.5em;
}

.footer__nav {
  margin-bottom: var(--mg-lg);
}

.footer__nav__item {
  text-align: center;
}

.footer__nav__item__link {
  color: var(--accent-azureblue);
  padding: var(--pd-sm) 0;
}

.footer__nav__item__link:hover {
  text-decoration: underline;
}

.footer__socials {
  margin-bottom: var(--mg-lg);
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
}

.footer__socials a {
  padding: 1.5em 1em;
}

.footer__socials a:hover {
  transform: scale(1.015);
}

.footer__socials a img {
  width: 50px;
}

@media (min-width: 120rem) {
  .footer__socials a img {
    width: 100px;
  }
}

@media (min-width: 320rem) {
  .footer__socials a img {
    width: 200px;
  }
}

.footer__info-icon {
  -ms-flex-item-align: center;
  align-self: center;
  width: 1.15em;
  height: 1.15em;
  display: inline-block;
}

.footer__pgp__link-container {
  margin-bottom: var(--pd-lg);
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.footer__pgp__key {
  margin-bottom: var(--pd-xs);
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.footer__pgp__key__icon {
  margin-right: var(--mg-xs);
}

.footer__pgp__btns__copy {
  margin-right: var(--pd-base);
  transition: all 2s ease-in;
  position: relative;
}

.footer__pgp__btns__copy:before {
  content: var(--pseudo_txt_copy);
  color: var(--accent-azureblue);
  display: none;
  position: absolute;
  bottom: -1.5em;
  left: -1em;
}

@media (min-width: 120rem) {
  .footer__pgp__btns__copy:before {
    bottom: -2em;
  }
}

.footer__pgp__btns__copy.js-copied:before {
  display: inline-block;
}

.footer__pgp__btns__copy__icon {
  width: 1em;
}

@media (min-width: 36rem) {
  .footer__pgp__modal__content-container {
    padding: var(--pd-lg) var(--pd-base);
  }
}

.footer__pgp__modal__title {
  text-align: center;
  color: var(--txtClr-lightgray);
  margin-bottom: var(--mg-sm);
}

.footer__pgp__modal__section-title {
  text-align: left;
  margin-bottom: var(--mg-xs);
}

.footer__pgp__modal__txt-container {
  margin: 0 auto var(--mg-base);
  max-width: 81ch;
}

.footer__pgp__modal__btn-container {
  text-align: center;
  margin: 0 auto var(--mg-lg);
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 24em;
  display: -ms-flexbox;
  display: flex;
}

.footer__pgp__modal__btn-container a, .footer__pgp__modal__btn-container button {
  font-size: var(--fs-xs);
  justify-content: space-evenly;
}

@media (min-width: 23.4375rem) {
  .footer__pgp__modal__btn-container a, .footer__pgp__modal__btn-container button {
    width: 84%;
    margin: 0 auto;
  }
}

@media (min-width: 26.5625rem) {
  .footer__pgp__modal__btn-container a, .footer__pgp__modal__btn-container button {
    font-size: var(--fs-sm);
  }
}

@media (min-width: 48rem) {
  .footer__pgp__modal__btn-container a, .footer__pgp__modal__btn-container button {
    font-size: var(--fs-base);
    width: 100%;
  }
}

@media (min-width: 120rem) {
  .footer__pgp__modal__btn-container a, .footer__pgp__modal__btn-container button {
    font-size: var(--fs-lg);
    width: 84%;
  }
}

@media (min-width: 160rem) {
  .footer__pgp__modal__btn-container a, .footer__pgp__modal__btn-container button {
    font-size: var(--fs-xl);
    width: 87%;
  }
}

@media (min-width: 320rem) {
  .footer__pgp__modal__btn-container a, .footer__pgp__modal__btn-container button {
    font-size: var(--fs-4xl);
    width: 100%;
  }
}

.footer__pgp__modal__btn-container > a:first-of-type {
  margin-bottom: var(--mg-base);
}

.footer__btc {
  margin: 0 auto var(--mg-lg);
  position: relative;
}

.footer__btc__button {
  -ms-flex-pack: center;
  justify-content: center;
  width: 12em;
  margin: 0 auto;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.footer__btc__button__logo {
  margin-right: var(--mg-base);
  width: 9em;
}

.footer__btc__modal__content-container {
  background-color: var(--bgClr-white);
  color: var(--txtClr-base);
}

@media (min-width: 26.5625rem) {
  .footer__btc__modal__content-container {
    padding: var(--pd-md) var(--pd-base);
  }
}

@media (min-width: 48rem) {
  .footer__btc__modal__content-container {
    padding: var(--pd-lg) var(--pd-md);
  }
}

@media (min-width: 64rem) {
  .footer__btc__modal__content-container {
    padding: var(--pd-xl) var(--pd-lg);
  }
}

@media (min-width: 85.375rem) {
  .footer__btc__modal__content-container {
    font-size: var(--fs-md);
  }
}

@media (min-width: 120rem) {
  .footer__btc__modal__content-container {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 160rem) {
  .footer__btc__modal__content-container {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 320rem) {
  .footer__btc__modal__content-container {
    font-size: var(--fs-4xl);
  }
}

.footer__btc__modal__image-container {
  margin: 0 auto var(--mg-lg);
  width: 6em;
}

.footer__btc__modal__txt-container h3 {
  font-size: var(--fs-lg);
  margin-bottom: var(--mg-sm);
}

@media (min-width: 85.375rem) {
  .footer__btc__modal__txt-container h3 {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 120rem) {
  .footer__btc__modal__txt-container h3 {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 160rem) {
  .footer__btc__modal__txt-container h3 {
    font-size: var(--fs-3xl);
  }
}

@media (min-width: 320rem) {
  .footer__btc__modal__txt-container h3 {
    font-size: var(--fs-6xl);
  }
}

@media (min-width: 64rem) {
  .footer__btc__modal__txt-container {
    max-width: 78ch;
    margin: 0 auto;
  }
}

.footer__btc__modal__txt-container .highlight-first-char:first-letter {
  text-transform: uppercase;
  float: left;
  margin-right: 5px;
  font-family: Monaco, Helvetica Neue;
  font-size: 4em;
  font-weight: 600;
  line-height: .85;
}

@media (min-width: 320rem) {
  .footer__btc__modal__txt-container .highlight-first-char:first-letter {
    margin-right: 20px;
  }
}

.footer__btc__modal__list li p:first-of-type {
  font-weight: 600;
}

.footer__onion {
  text-align: center;
  margin-bottom: var(--mg-lg);
  -ms-flex-flow: column;
  flex-flow: column;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

@media (min-width: 64rem) {
  .footer__onion {
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.footer__copyright {
  text-align: center;
  font-size: var(--fs-sm);
}

@media (min-width: 120rem) {
  .footer__copyright {
    font-size: var(--fs-md);
  }
}

@media (min-width: 160rem) {
  .footer__copyright {
    font-size: var(--fs-xl);
  }
}

.footer__copyright hr {
  margin-bottom: 4em;
}

.footer__copyright p {
  padding-bottom: 4em;
}

.footer .Matthew6 {
  z-index: -1;
  padding: var(--pd-base);
  background-color: var(--bgClr);
  border-radius: .5em;
  max-width: 25em;
  margin: 0 auto;
  position: absolute;
  top: 10em;
}

.footer .Matthew6 h4 {
  text-align: center;
  margin-bottom: var(--mg-sm);
}

.error {
  min-height: 80vh;
  position: relative;
}

.error__background-image {
  background: url("error404.de45ee99.png") center / cover no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.error__content-container {
  z-index: 1;
  text-align: center;
  padding: var(--pd-base);
  background-color: rgba(255, 63, 115, .89);
  border-radius: 5px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 48rem) {
  .error__content-container {
    font-size: var(--fs-base-plus);
    width: 80%;
  }
}

@media (min-width: 64rem) {
  .error__content-container {
    font-size: var(--fs-lg);
  }
}

@media (min-width: 120rem) {
  .error__content-container {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 160rem) {
  .error__content-container {
    font-size: var(--fs-2xl);
  }
}

@media (min-width: 320rem) {
  .error__content-container {
    font-size: var(--fs-4xl);
  }
}

.error__text {
  color: #fff;
  text-shadow: 0 1px #000;
  text-transform: uppercase;
  font-weight: 600;
}

.error__text:last-of-type {
  margin-bottom: var(--mg-base);
}

.main__content-container {
  padding: var(--pd-base) var(--pd-sm);
  background-color: var(--bgClr-lightgray);
  margin: var(--mg-base) auto var(--mg-2xl);
  border-radius: 5px;
  width: 95%;
}

@media (min-width: 26.5625rem) {
  .main__content-container {
    padding: var(--pd-base) var(--pd-base);
  }
}

@media (min-width: 48rem) {
  .main__content-container p, .main__content-container h3, .main__content-container h4 {
    max-width: 50em;
  }
}

@media (min-width: 120rem) {
  .main__content-container {
    font-size: var(--fs-xl);
  }
}

@media (min-width: 160rem) {
  .main__content-container {
    font-size: var(--fs-2xl);
  }
}

.main__download-box {
  margin-bottom: var(--mg-base);
  max-width: 30em;
}

.txt__section-container {
  padding: 0 var(--pd-base);
}

.txt__version {
  margin-bottom: var(--mg-base);
}

.txt__intro p {
  margin-bottom: var(--mg-sm);
}

.txt__section {
  margin-bottom: var(--mg-xs);
}

.txt__section h3:hover {
  cursor: pointer;
}

.txt__section h3:before {
  content: "|";
  float: left;
  margin-right: 5px;
  font-weight: bold;
}

.txt__section h3:after {
  content: "+";
  color: var(--txtClr-accent);
  float: right;
  margin-left: 5px;
  font-weight: bold;
}

.txt__section h3.open:after {
  content: "−";
}

.txt__section a {
  color: var(--txtClr-accent);
  font-weight: bold;
}

.txt__collapse {
  padding: var(--pd-sm) var(--pd-sm);
  display: none;
}

.txt__collapse.open {
  display: block;
}

.nav__btn {
  margin: 0 auto var(--mg-2xl);
  text-align: center;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos] {
  pointer-events: none;
}

[data-aos].aos-animate {
  pointer-events: auto;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

@media screen {
  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 0;
    transition-property: opacity, transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"].aos-animate {
    opacity: 1;
    transform: none;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: translate3d(0, 100px, 0);
  }

  html:not(.no-js) [data-aos="fade-down"] {
    transform: translate3d(0, -100px, 0);
  }

  html:not(.no-js) [data-aos="fade-right"] {
    transform: translate3d(-100px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-left"] {
    transform: translate3d(100px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-up-right"] {
    transform: translate3d(-100px, 100px, 0);
  }

  html:not(.no-js) [data-aos="fade-up-left"] {
    transform: translate3d(100px, 100px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-right"] {
    transform: translate3d(-100px, -100px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-left"] {
    transform: translate3d(100px, -100px, 0);
  }

  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"] {
    opacity: 0;
    transition-property: opacity, transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"].aos-animate {
    opacity: 1;
    transform: translateZ(0)scale(1);
  }

  html:not(.no-js) [data-aos="zoom-in"] {
    transform: scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-up"] {
    transform: translate3d(0, 100px, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-down"] {
    transform: translate3d(0, -100px, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-right"] {
    transform: translate3d(-100px, 0, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-left"] {
    transform: translate3d(100px, 0, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-out"] {
    transform: scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-up"] {
    transform: translate3d(0, 100px, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-down"] {
    transform: translate3d(0, -100px, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-right"] {
    transform: translate3d(-100px, 0, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-left"] {
    transform: translate3d(100px, 0, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos^="slide"][data-aos^="slide"] {
    visibility: hidden;
    transition-property: transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^="slide"][data-aos^="slide"].aos-animate {
    visibility: visible;
    transform: translateZ(0);
  }

  html:not(.no-js) [data-aos="slide-up"] {
    transform: translate3d(0, 100%, 0);
  }

  html:not(.no-js) [data-aos="slide-down"] {
    transform: translate3d(0, -100%, 0);
  }

  html:not(.no-js) [data-aos="slide-right"] {
    transform: translate3d(-100%, 0, 0);
  }

  html:not(.no-js) [data-aos="slide-left"] {
    transform: translate3d(100%, 0, 0);
  }

  html:not(.no-js) [data-aos^="flip"][data-aos^="flip"] {
    backface-visibility: hidden;
    transition-property: transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos="flip-left"] {
    transform: perspective(2500px)rotateY(-100deg);
  }

  html:not(.no-js) [data-aos="flip-left"].aos-animate {
    transform: perspective(2500px)rotateY(0);
  }

  html:not(.no-js) [data-aos="flip-right"] {
    transform: perspective(2500px)rotateY(100deg);
  }

  html:not(.no-js) [data-aos="flip-right"].aos-animate {
    transform: perspective(2500px)rotateY(0);
  }

  html:not(.no-js) [data-aos="flip-up"] {
    transform: perspective(2500px)rotateX(-100deg);
  }

  html:not(.no-js) [data-aos="flip-up"].aos-animate {
    transform: perspective(2500px)rotateX(0);
  }

  html:not(.no-js) [data-aos="flip-down"] {
    transform: perspective(2500px)rotateX(100deg);
  }

  html:not(.no-js) [data-aos="flip-down"].aos-animate {
    transform: perspective(2500px)rotateX(0);
  }
}

/*# sourceMappingURL=index.acaaa910.css.map */
