.fun-zone {
  overflow: hidden;

  &__content-container {
    position: relative;
  }

  &__heading {
    /* for styles of the section title, see __section */
    text-align: center;
    margin-bottom: var(--mg-lg);

    &__subtitle {
      font-size: var(--fs-base-plus);
      font-style: italic;
      font-weight: 300;
      max-width: 55ch;
      margin: -2em auto 0 auto; //base margin from __section is too much

      @include pc_lg {
        font-size: var(--fs-xl);
      }
      @include pc_xl {
        font-size: var(--fs-2xl);
      }
      @include pc_2xl {
        font-size: var(--fs-4xl);
      }
    }
  }
}
