.text-emphasis {
  font-style: italic;

  &--accent {
    color: var(--bgClr-accent);
    font-weight: 600;
  }

  &--underline {
    border-bottom: 6px solid var(--bgClr-accent);
    border-radius: 5px;
  }

  &--bg {
    border-radius: 2px;
    line-height: 1.66;
    padding: 0.06em 0.12em;

    &-accent {
      background-color: var(--bgClr-accent);
      color: white;
    }
    &-white {
      background-color: white;
      color: var(--bgClr-accent);
    }
  }
}

.text-strong {
  color: var(--bgClr-accent);
}
