.tooltip {
  /* parent should have position:relative; */
  display: none;

  &--active {
    display: block;
    animation: fade-in 500ms forwards;
  }

  &--closing {
    display: block;
    animation: fade-out 500ms forwards;
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 6; // see stacking context note __global.scss
  overflow-y: auto;

  background-color: rgba(196, 240, 255, 0.75);

  margin: 0 auto;

  font-size: var(--fs-sm);

  &__content-container {
    /* 
      There is a trade-off here: positioning the content in the center works very well on mobile. But, on bigger screens (pc_md) some users may have to scroll up or down slightly to read the entire message.
    */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 85%;

    background-color: var(--bgClr-dark);
    color: var(--txtClr-white);

    border-radius: 5px;

    padding: var(--pd-md);

    @include tablet_md {
      max-width: 55ch;
    }
    @include pc_sm {
      font-size: var(--fs-base);
      padding: var(--pd-base) var(--pd-base);
    }
    @include pc_lg {
      font-size: var(--fs-md);
    }
    @include pc_xl {
      font-size: var(--fs-xl);
    }
    @include pc_2xl {
      font-size: var(--fs-3xl);
    }
  }

  &__text:last-of-type {
    margin-bottom: var(--mg-sm);
  }
}
