.block-link {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: var(--bgClr-white);
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.35);
  border-radius: 5px;

  font-size: var(--fs-sm);

  padding: var(--pd-base) var(--pd-xs);
  margin-bottom: var(--mg-base);

  @include tablet_md {
    flex-direction: row;
    justify-content: flex-start;
    padding: var(--pd-base) var(--pd-base);
  }
  @include pc_lg {
    font-size: var(--fs-base);
  }
  @include pc_2xl {
    font-size: var(--fs-2xl);
  }

  &__image-container {
    margin-bottom: var(--mg-base);

    @include tablet_md {
      margin: 0 var(--mg-sm) 0 0;
    }
  }

  &__image {
    width: 8.1em;
    border-radius: 5px;
    margin: 0 auto;

    @include tablet_md {
      width: 9.6em;
    }
    @include pc_2xl {
      width: 12em;
    }
  }

  &__desc {
    padding: 0 var(--pd-xs);
    text-align: center;

    @include mobile_md {
      padding: 0 var(--pd-sm);
    }
    @include tablet_md {
      text-align: left;
    }

    &__text {
      margin-bottom: var(--mg-base) !important;

      @include tablet_md {
        margin-bottom: var(--mg-sm) !important;
      }
    }

    &__cta-container {
      display: flex;
      justify-content: center;

      @include tablet_md {
        justify-content: flex-start;
      }
    }

    &__icon {
      width: 2.4em;
      margin-right: var(--mg-sm);
    }

    &__cta {
      align-self: center;
      text-decoration: underline;
    }
  }

  &__link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 5;
  }
}
