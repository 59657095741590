.footer {
  position: relative; //see modal class

  color: var(--accent-azureblue);

  background-color: var(--bgClr-darkest); //#0e161b;
  border-top: 6px #00b4ff solid;

  padding: var(--pd-xl) var(--pd-base) 0;

  @include pc_lg {
    font-size: var(--fs-xl);
  }

  @include pc_xl {
    font-size: var(--fs-2xl);
  }

  @include pc_2xl {
    font-size: var(--fs-4xl);
  }

  &__snow-animation-switch {
    margin-bottom: var(--mg-lg);
  }

  &__company-info {
    margin-bottom: var(--mg-lg);

    &__logo {
      position: relative;

      width: 9em;

      margin: 0 auto var(--mg-sm);

      &__link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &__content-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__num {
      text-transform: capitalize;
      color: var(--txtClr-white);
    }

    &__icon {
      width: 1.5em;
      margin-right: var(--mg-xs);
    }
  }

  &__nav {
    margin-bottom: var(--mg-lg);

    &__item {
      text-align: center;

      &__link {
        color: var(--accent-azureblue);
        padding: var(--pd-sm) 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    margin-bottom: var(--mg-lg);

    a {
      padding: 1.5em 1em;

      &:hover {
        transform: scale(1.015);
      }

      img {
        width: 50px;

        @include pc_lg {
          width: 100px;
        }

        @include pc_2xl {
          width: 200px;
        }
      }
    }
  }

  &__info-icon {
    display: inline-block;
    width: 1.15em;
    height: 1.15em;
    align-self: center;
  }

  &__pgp {
    &__link-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: var(--pd-lg);
    }

    &__key {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--pd-xs);

      &__icon {
        margin-right: var(--mg-xs);
      }
    }

    &__btns {
      &__copy {
        position: relative;

        transition: all 2s ease-in;

        margin-right: var(--pd-base);

        &::before {
          // this is the pop-up message
          content: var(--pseudo_txt_copy);
          display: none;
          color: var(--accent-azureblue);

          position: absolute;
          bottom: -1.5em;
          left: -1em;

          @include pc_lg {
            bottom: -2em;
          }
        }

        &.js-copied {
          &::before {
            display: inline-block;
          }
        }

        &__icon {
          width: 1em;
        }
      }
    }

    &__modal {
      &__content-container {
        @include tablet_sm {
          padding: var(--pd-lg) var(--pd-base);
        }
      }

      &__title {
        text-align: center;
        color: var(--txtClr-lightgray);
        margin-bottom: var(--mg-sm);
      }

      &__section-title {
        text-align: left;
        margin-bottom: var(--mg-xs);
      }

      &__txt-container {
        max-width: 81ch;
        margin: 0 auto var(--mg-base);
      }

      &__btn-container {
        display: flex;
        flex-direction: column;

        max-width: 24em;

        text-align: center;

        margin: 0 auto var(--mg-lg);

        /*  
            These btns have long text, especially in some translations.
            Tone down the font-size on small mobile screens.
            Base styles see __btns.scss. 
        */
        & a,
        button {
          font-size: var(--fs-xs);
          justify-content: space-evenly;

          @include mobile_md {
            width: 84%;
            margin: 0 auto;
          }

          @include mobile_lg {
            font-size: var(--fs-sm);
          }

          @include tablet_md {
            font-size: var(--fs-base);
            width: 100%;
          }

          @include pc_lg {
            font-size: var(--fs-lg);
            width: 84%;
          }

          @include pc_xl {
            font-size: var(--fs-xl);
            width: 87%;
          }

          @include pc_2xl {
            font-size: var(--fs-4xl);
            width: 100%;
          }
        }

        & > a:first-of-type {
          margin-bottom: var(--mg-base);
        }
      }
    }
  }

  &__btc {
    position: relative; //modal
    margin: 0 auto var(--mg-lg);

    &__button {
      position: relative; // button-tag element is steccht out on top

      display: flex;
      justify-content: center;

      width: 12em;
      margin: 0 auto;

      &__logo {
        margin-right: var(--mg-base);
        width: 9em;
      }
    }

    &__modal {
      &__content-container {
        background-color: var(--bgClr-white);
        color: var(--txtClr-base);

        @include mobile_lg {
          padding: var(--pd-md) var(--pd-base);
        }

        @include tablet_md {
          padding: var(--pd-lg) var(--pd-md);
        }

        @include pc_sm {
          padding: var(--pd-xl) var(--pd-lg);
        }

        @include pc_md {
          font-size: var(--fs-md);
        }

        @include pc_lg {
          font-size: var(--fs-lg);
        }

        @include pc_xl {
          font-size: var(--fs-xl);
        }

        @include pc_2xl {
          font-size: var(--fs-4xl);
        }
      }

      &__image-container {
        width: 6em;
        margin: 0 auto var(--mg-lg);
      }

      &__txt-container {
        h3 {
          font-size: var(--fs-lg);
          margin-bottom: var(--mg-sm);

          @include pc_md {
            font-size: var(--fs-xl);
          }
          @include pc_lg {
            font-size: var(--fs-2xl);
          }
          @include pc_xl {
            font-size: var(--fs-3xl);
          }
          @include pc_2xl {
            font-size: var(--fs-6xl);
          }
        }

        @include pc_sm {
          max-width: 78ch;
          margin: 0 auto;
        }

        .highlight-first-char::first-letter {
          @include highlight-first-char;
        }
      }

      &__list {
        li {
          p:first-of-type {
            font-weight: 600;
          }
        }
      }
    }
  }

  &__onion {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;

    text-align: center;

    margin-bottom: var(--mg-lg);

    @include pc_sm {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &__copyright {
    text-align: center;
    font-size: var(--fs-sm);

    @include pc_lg {
      font-size: var(--fs-md);
    }
    @include pc_xl {
      font-size: var(--fs-xl);
    }

    hr {
      margin-bottom: 4em;
    }

    p {
      padding-bottom: 4em;
    }
  }

  .Matthew6 {
    z-index: -1; // see stacking context note __global.scss

    position: absolute;
    top: 10em;
    margin: 0 auto;
    padding: var(--pd-base);

    max-width: 25em;

    background-color: var(--bgClr);
    border-radius: 0.5em;

    h4 {
      text-align: center;
      margin-bottom: var(--mg-sm);
    }
  }
}
