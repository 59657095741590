.txt-link {
  color: var(--txtClr-base);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;

  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &--lightblue {
    color: var(--txtClr-lightblue);
    text-decoration: none;
  }

  &--orange {
    color: var(--txtClr-orange);
    text-decoration: none;
  }

  &--accent {
    color: var(--bgClr-accent);
  }

  &__icon {
    width: 0.9em;
    height: 0.9em;
    margin: 0 0.375em 0;
    align-self: center;
  }
}
