.icon-group-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0 auto var(--mg-base);

  @include tablet_sm {
    max-width: 45ch;
  }

  img {
    max-width: 3em;
  }
}
