#snowfall {
  transform: translate3d(0, 0, 0);
  will-change: transform;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(0.6rem * 3.8);
  }
  66.6% {
    opacity: 1;
    transform: translateY(0.6rem * 5.2);
  }
  100% {
    opacity: 0;
    transform: translateY(0.6rem * 8) scale(0.5);
  }
}

@keyframes move-chevron--lg {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateY(1rem * 3.8);
  }
  66.6% {
    opacity: 1;
    transform: translateY(1rem * 5.2);
  }
  100% {
    opacity: 0;
    transform: translateY(1rem * 8) scale(0.5);
  }
}
