.scroll-down-indicator {
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 1;

  transition: opacity 0.6s ease-in-out;

  &.hide {
    opacity: 0;
  }
}

.chevron {
  position: absolute;
  width: 0.6rem * 3.5;
  height: 0.6rem * 0.8;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;

  @include pc_lg {
    width: 1rem * 3.5;
    height: 1rem * 0.8;
    animation: move-chevron--lg 3s ease-out infinite;
  }

  @include pc_2xl {
    width: 2rem * 3.5;
    height: 2rem * 0.8;
  }
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron::before,
.chevron::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #2c3e50;
}

.chevron::before {
  left: 0;
  transform: skewY(30deg);
}

.chevron::after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}
